import PropTypes from 'prop-types';
import React from 'react';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

import ContentBlock from 'src/components/functional/content-block';
import {H1} from 'src/components/functional/headings';
import DynamicBreadcrumb from 'src/components/functional/dynamic-breadcrumb';
import {theme} from 'src/styles/theme';


const HeroSection = styled.section`
  min-height: 94vh;
`;

const Hero = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 44vh;
  max-height: 44vh;
  width: 100%;
`;

const HeroText = styled.div`
  width: 100%;
  & .container{
    padding: 4vh 0 2vh 0;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-bottom: 1px solid ${theme.mediumGray};
  }
`;

const FeaturedHeroSection = (props) => {
  return (
    <HeroSection>
      <Hero image={props.bgImage}/>
      <HeroText>
        <Container>
          <ContentBlock
            header={(hprops) =>
              <H1
                className="text-primary"
                display={props.display ?? 1}
                light={true}
                noUnderline={true}
                {...hprops}
              />}
            content={props.content}
            tag={props.tag}
            contentWrapper={(props) => <div className="lead" {...props}/>}
          />
          {props.breadcrumbs && <DynamicBreadcrumb />}
        </Container>
      </HeroText>
    </HeroSection>
  );
};

FeaturedHeroSection.propTypes = {
  bgImage: PropTypes.any,
  breadcrumbs: PropTypes.bool,
  content: PropTypes.any,
  tag: PropTypes.string.isRequired,
  display: PropTypes.number,
};

export default FeaturedHeroSection;
