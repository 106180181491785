import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';
import styled from 'styled-components';

import {Col, Container, Row} from 'react-bootstrap';
import {theme} from 'src/styles/theme';
import {breakpoints} from 'src/styles/breakpoints';
import Page from 'src/components/functional/page';
import FeaturedHeroSection
  from 'src/components/functional/hero-section-featured';
import ContentBlock from 'src/components/functional/content-block';
import {H2, H3} from 'src/components/functional/headings';
import HeroImage from 'src/images/our-philosophy-hero.jpg';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import ArbiasButton from 'src/components/functional/arbias-button';
import Tile from 'src/components/functional/tile';
import {getResource} from 'src/functionality/resources-provider';

const MissionVisionSection = styled.section`
  padding: 5vh 0;
`;

const ValuesSection = styled.section`
  padding: 5vh 0;
  background-color: ${theme.bodyBg};
`;

const ClientCentredSection = styled.section`
  padding: 5vh 0;
`;

const ValuesGrid = styled.div`
  margin-top: 1.5em;
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(3, 1fr);
  
  & > div {
    place-self: center;
    
  }
  @media ${breakpoints.md} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${breakpoints.sm} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Bold = styled.h6`
  font-weight: bold;
`;


const GovernanceSection = styled.section`
  padding: 5vh 0;
`;
const RAPSection = styled.section`
  padding: 5vh 0;
  background-color: ${theme.bodyBg};
`;

const OurPhilosophyPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);

  const flags = useFeatureFlags();

  return (
    <Page white={true}>
      <FeaturedHeroSection
        bgImage={HeroImage}
        breadcrumbs={true}
        content={content}
        tag="OP01"
      />
      <MissionVisionSection>
        <Container>
          <ContentBlock
            header={(props) => <H2 {...props}/>}
            content={content}
            tag="OP02"
          />
          <ContentBlock
            header={(props) => <H2 {...props}/>}
            content={content}
            tag="OP03"
          />
          <Row>
            {['OP03a', 'OP03b', 'OP03c', 'OP03d', 'OP03e', 'OP03f', 'OP03g']
                .map((tag, key) =>
                  <Col key={key} md={6} sm={12}>
                    <ContentBlock
                      header={(props) => <Bold {...props}/>}
                      content={content}
                      tag={tag}
                    />
                  </Col>,
                )}
          </Row>
        </Container>
      </MissionVisionSection>
      <ValuesSection>
        <Container>
          <H2>Our Values</H2>
          <ValuesGrid>
            {['OP04a', 'OP04b', 'OP04c', 'OP04d', 'OP04e', 'OP04f']
                .map((tag, key) =>
                  <div key={key}>
                    <ContentBlock
                      header={(props) => <Bold {...props}/>}
                      content={content}
                      tag={tag}
                    />
                  </div>,
                )}
          </ValuesGrid>
        </Container>
      </ValuesSection>
      {flags.OurPhilosophy_ClientCentredSupport &&
        <ClientCentredSection>
          <Container>
            <Row>
              <Col md={12} lg={6}>
                <ContentBlock
                  header={H3}
                  content={content}
                  tag="OP10"
                />
              </Col>
              <Col md={12} lg={6}>
                {/* eslint-disable-next-line max-len */}
                {/* <img src={clientCentredPlanningDiagram} alt={content?.OP10?.title} /> */}
                <img src="https://picsum.photos/300/200/?blur=2" alt="" />
              </Col>
            </Row>
          </Container>
        </ClientCentredSection>
      }
      <Container>
        <GovernanceSection>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='OP05'
            linkText='Governance'
            link={
              (props) => <ArbiasButton
                to="/about/people/management-and-advisors"
                {...props}
              /> }
          />
        </GovernanceSection>
      </Container>
      <RAPSection>
        <Container>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='OP06'
          />
          <Tile
            isRounded
            type='document'
            onClick={() =>
            // eslint-disable-next-line max-len
              getResource('resources/Policies-plans-and-procedures/Reconciliation-Action-Plan.pdf')
                  .then((url) => window.open(url))}
            title='Reconciliation Action Plan'
          />
        </Container>
      </RAPSection>
    </Page>
  );
};

OurPhilosophyPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default OurPhilosophyPage;
